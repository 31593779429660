import React, { useEffect, useState } from 'react';
import './App.css';
import { mapperServiceWithDefaultFetchJson } from './MapperService';

function App() {

  const [helpUrl, setHelpUrl] = useState<string>()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const component = urlParams.get('component');
    const version = urlParams.get('version');
    const language = urlParams.get('language');
    const helpID = urlParams.get('helpid');

    const helpUrlPromise = mapperServiceWithDefaultFetchJson.mapHelpIdToUrl(component!, version!, language!, helpID, "/components/")
    helpUrlPromise.then((helpUrl) => {
      setHelpUrl(helpUrl)
      setTimeout(() => {
        window.location.href = helpUrl
      }, 5000)
    })
  }, [])

  return (
    <div className="App">
      <header className="App-header">
          <p>Redirecting to help article {helpUrl} <b id="helpUrl"></b></p> in 5 seconds. ESC might stop it.
        </header>
      </div>
    );
}

export default App;
