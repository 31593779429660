// Mapper service can be used with another "fetchJson" to implement some unit tests.
export const mapperService = (fetchJson: (url: string) => Promise<any>) => {
    function matchesVersion(versionRegex: string, version: string) {
        const regex = new RegExp(versionRegex)
        return regex.test(version)
    }

    function normalizeUrl(url: string) {
        const cleandUrl = url.replace("/./", "/").replace("//", "/")
        return cleandUrl
    }

    function withTrailingSlash(s: string) {
        if (s.length === 0) {
            return "/"
        } else if (s[s.length - 1] !== "/") {
            return s + "/"
        } else {
            return s
        }
    }

    function getDirForFileUrl(fileUrl: string) {
        const dirUrl = fileUrl.split("/").slice(0, -1).join("/")
        return withTrailingSlash(dirUrl)
    }

    function matchesLanguage(languageRegex: string, language: string) {
        const regex = new RegExp(languageRegex)
        return regex.test(language)
    }

    async function getHelpUrlForHelpIdMapping(helpIdMappingUrl: string, mappingTargetBaseDir: string, helpId: string) {
        const helpIdMapping = await fetchJson(helpIdMappingUrl)
        const mappingItem = helpIdMapping.find((item: any) => item.HelpID === helpId)
        let helpUrl = null
        if (mappingItem) {
            helpUrl = normalizeUrl(getDirForFileUrl(helpIdMappingUrl) + withTrailingSlash(mappingTargetBaseDir) + mappingItem.HelpUrl)
        }
        return helpUrl
    }

    async function getHelpUrlForLanguageList(languageListUrl: string, language: string, helpId: string | null) {
        const languageList = await fetchJson(languageListUrl)
        const languageItem = languageList.languages.find((langItem: any) => matchesLanguage(langItem.languageRegex, language))
        let helpUrl = null
        if (languageItem) {
            const helpIdMappingUrl = normalizeUrl(getDirForFileUrl(languageListUrl) + languageItem.helpIdMappingFile)
            if (helpId != null) {
                helpUrl = await getHelpUrlForHelpIdMapping(helpIdMappingUrl, languageItem.mappingTargetBaseDir, helpId)
            } else {
                helpUrl = normalizeUrl(getDirForFileUrl(languageListUrl) + languageItem.noMappingFallbackPage)
            }
        }
        return helpUrl
    }

    async function getHelpUrlForVersionList(versionListUrl: string, version: string, language: string, helpId: string | null) {
        const versionList = await fetchJson(versionListUrl)
        var foundVersion = versionList.versions.find((versionItem: any) => matchesVersion(versionItem.versionRegex, version))
        var helpUrl = null

        if (!foundVersion && versionList.length > 0) {
            foundVersion = versionList.versions[versionList.versions.length - 1]
        }

        if (foundVersion) {
            const languageListUrl = normalizeUrl(getDirForFileUrl(versionListUrl) + foundVersion.languagesListFile)
            helpUrl = await getHelpUrlForLanguageList(languageListUrl, language, helpId)
        }
        return helpUrl
    }

    const mapHelpIdToUrl = async (componentName: string, version: string, language: string, helpId: string | null, baseUrl: string) => {
        const componentList = await fetchJson(baseUrl + "components.json")
        const foundComponent = componentList.components.find((component: any) => componentName === component.registeredComponentName);
        let helpUrl = null
        if (foundComponent) {
            const versionListUrl = baseUrl + foundComponent.versionListFile
            helpUrl = await getHelpUrlForVersionList(versionListUrl, version, language, helpId)
        }

        if (!helpUrl) {
            helpUrl = baseUrl + componentList.componentUnknownPath
        }

        return helpUrl
    }


    return {
        mapHelpIdToUrl
    }
}

async function fetchJson(url: string) {
    const response = await fetch(url)
    if (!response.ok) {
        throw new Error("Failed to fetch '" + url + "': " + response.statusText)
    }
    const text = await response.text()
    return JSON.parse(text)
}

export const mapperServiceWithDefaultFetchJson = mapperService(fetchJson)
